import "normalize.css";

import * as React from "react";
import Helmet from "react-helmet";

import Navbar from "./Navbar";
import Footer from "./Footer";

import posed from "react-pose";

import "../assets/styles/index.sass";

// fix: to support hooks
import { setConfig } from "react-hot-loader";
import { getLanguage } from "../getLanguage";
setConfig({ pureSFC: true } as any);

const Transition = posed.div({
  enter: {
    opacity: 1
  },
  exit: {
    opacity: 0
  }
});

interface ILayoutProps extends React.Props<any> {
  enUrl?: string;
  deUrl?: string;
  showContactButton?: boolean;
}

const lang = getLanguage();

export default (props: ILayoutProps) => (
  <div className="container mx-auto">
    <Helmet
      defaultTitle="KNAACK.IT"
      titleTemplate="KNAACK.IT - %s"
      htmlAttributes={{ lang }}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1.0, minimum-scale=1.0"
        }
      ]}
    />
    <Navbar enUrl={props.enUrl} deUrl={props.deUrl} />
    {/* <Transition> */}
    <div id="content-area">{props.children}</div>
    {/* </Transition> */}
    <Footer showContactButton={props.showContactButton} />
  </div>
);
