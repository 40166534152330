import * as React from "react";
import { useState } from "react";
import { Link } from "gatsby";
import { bubble as Menu } from "react-burger-menu";
import logo from "../assets/img/logo.svg";
import { getLanguage } from "../getLanguage";

interface INavbar {
  enUrl?: string;
  deUrl?: string;
}

export default (props: INavbar) => {
  const lang = getLanguage();
  const [burgerMenuIsOpen, setBurgerMenuIsOpen] = useState(false);
  const closeBurgerMenu = () => setBurgerMenuIsOpen(false);
  return (
    <div className="nav-container">
      <div>
        <Link to={lang === "de" ? "/de/" : "/en/"} title="homepage" style={{ border: "none" }}>
          <img
            src={logo}
            style={{ width: 170, height: 50, maxWidth: 170, maxHeight: 50 }}
            alt="KNAACK.IT - Software Development & Consulting - Logo"
          />
        </Link>
      </div>
      <div>
        <div className="hidden md:block">
          {lang === "de" ? (
            <Link to={props.deUrl} className="active" title="german">
              DE
            </Link>
          ) : (
            <Link to={props.deUrl} title="german">
              DE
            </Link>
          )}
          &nbsp;|&nbsp;
          {lang === "en" ? (
            <Link to={props.enUrl} className="active" title="english">
              EN
            </Link>
          ) : (
            <Link to={props.enUrl} title="english">
              EN
            </Link>
          )}
        </div>
      </div>
      <div className=" md:hidden">
        <div>
          <Menu width={156} right isOpen={burgerMenuIsOpen}>
            {lang === "en" ? (
              <React.Fragment>
                <div className="bm-menu-full">
                  <Link to="/en" onClick={closeBurgerMenu} title="german">
                    Home
                  </Link>
                </div>
                <div className="bm-menu-full">
                  <Link to="/en/contact" onClick={closeBurgerMenu} title="german">
                    Contact
                  </Link>
                </div>
                <div className="bm-menu-full">
                  <Link to="/en/imprint" onClick={closeBurgerMenu} title="german">
                    Imprint
                  </Link>
                </div>
                <div className="bm-menu-full lang">
                  <Link to="/de" onClick={closeBurgerMenu} title="german">
                    Deutsch
                  </Link>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="bm-menu-full">
                  <Link to="/de" onClick={closeBurgerMenu} title="german">
                    Startseite
                  </Link>
                </div>
                <div className="bm-menu-full">
                  <Link to="/de/kontakt" onClick={closeBurgerMenu} title="german">
                    Kontakt
                  </Link>
                </div>
                <div className="bm-menu-full">
                  <Link to="/de/impressum" onClick={closeBurgerMenu} title="german">
                    Impressum
                  </Link>
                </div>
                <div className="bm-menu-full lang">
                  <Link to="/en" onClick={closeBurgerMenu} title="english">
                    English
                  </Link>
                </div>
              </React.Fragment>
            )}
          </Menu>
        </div>
      </div>
    </div>
  );
};
