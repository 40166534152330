import * as React from "react";
import { Link } from "gatsby";
import { getLanguage } from "../getLanguage";

interface IFooterProps {
  showContactButton?: boolean;
}

const Footer = (props: IFooterProps) => {
  const lang = getLanguage();
  return (
    <React.Fragment>
      <footer>
        {props.showContactButton && (
          <section>
            <Link className="button" to={`/${lang === "en" ? "en/contact" : "de/kontakt"}`}>
              {lang === "de" ? "Jetzt Kontakt aufnehmen" : "Contact us now"}
            </Link>
          </section>
        )}
        <p className="links">
          {lang === "en" ? <Link to="/en/imprint">Imprint</Link> : <Link to="/de/impressum">Impressum</Link>} |{" "}
          {lang === "en" ? <Link to="/en/contact">Contact</Link> : <Link to="/de/kontakt">Kontakt</Link>}
        </p>
        <p className="copyright">Copyright {new Date().getFullYear()} - KNAACK.IT </p>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
